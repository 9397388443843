"use strict";
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
// Import our custom CSS
import '../scss/login-page.scss';
import { Container, Row, Col, Stack, Image, Alert, Button, FloatingLabel, Form, Card } from 'react-bootstrap';
function goTo(path) {
    if (path)
        window.location.replace(path);
    else
        window.location.reload();
}
function ResetPage() {
    const [isDone, setIsDone] = React.useState(false);
    const [email, setEmail] = React.useState('');
    function reset() {
        if (email != '') {
            const formData = new FormData();
            formData.append('email', email);
            fetch(`${urlBase}/admin/api/reset-password`, { method: "POST", body: formData }).then(data => data.json()).then((res) => {
                setIsDone(true);
            }).catch(e => console.error(e.message));
        }
    }
    return React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(Row, null,
                React.createElement(Col, { sm: 8, md: 6, lg: 5, xl: 4, xxl: 3, className: 'mx-auto' },
                    React.createElement("div", { className: 'spacer-150' }),
                    React.createElement(Stack, { className: 'bg-white text-black rounded p-3 my-auto align-items-center', direction: 'vertical', gap: 5 },
                        React.createElement("h1", null, "Reset Password"),
                        React.createElement(Image, { width: 70, height: 70, src: `${urlBase}/images/twin-logo-white.svg` }),
                        React.createElement(Stack, { gap: 2, className: 'mx-3' },
                            React.createElement(FloatingLabel, { controlId: "floatingInput", label: "Email address" },
                                React.createElement(Form.Control, { value: email, type: "email", placeholder: "name@example.com", className: 'bg-white text-black', onChange: (e) => setEmail(e.target.value), disabled: isDone }))),
                        React.createElement("small", null, "If your email address is found in the system, email will be sent with a temporary password."),
                        React.createElement(Button, { className: 'py-2 px-5 pill-shape', disabled: isDone, onClick: reset }, "RESET"),
                        React.createElement("small", { className: 'clickable', onClick: () => goTo(`${urlBase}/`) }, "Back to Login page"))))),
        React.createElement(Alert, { show: isDone, className: 'bg-success fixed-top', dismissible: true, onClose: () => { setIsDone(false); } },
            React.createElement(Alert.Heading, null, "Done!"),
            React.createElement("p", null, "Request is made. Please check your email.")));
}
function LoginPage(props) {
    const [isError, setIsError] = React.useState(false);
    const [isTemp, setIsTemp] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [providers, setProviders] = React.useState([]);
    function login() {
        if (email != '' && password != '') {
            const formData = new FormData();
            formData.append('email', email);
            formData.append('password', password);
            fetch(`${urlBase}/admin/api/login`, { method: "POST", body: formData }).then(data => data.json()).then((res) => {
                if (res.error) {
                    console.log('login error:', res.error);
                    setIsError(true);
                }
                else if (res.isTemporary)
                    setIsTemp(true);
                else
                    goTo(nextPage);
            }).catch(e => console.error(e.message));
        }
    }
    function providerLogin(provider) {
        window.open(`${urlBase}/authorize/${provider}`, '_self');
    }
    React.useEffect(() => {
        if (loginProviders) {
            const provs = loginProviders.split(',').map(a => {
                const parts = a.split(':');
                if (parts.length == 2)
                    return { name: parts[0].toUpperCase(), provider: parts[1] };
                else
                    return { name: a.toUpperCase(), provider: a };
            });
            setProviders(provs);
        }
    }, []);
    const providerEls = providers.map(a => React.createElement(Card, { className: 'p-2 text-center clickable', key: a.provider, onClick: () => providerLogin(a.provider) }, a.name));
    return React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(Row, null,
                React.createElement(Col, { sm: 8, md: 6, lg: 5, xl: 4, xxl: 3, className: 'mx-auto' },
                    React.createElement("div", { className: 'spacer-150' }),
                    React.createElement(Stack, { className: 'bg-white text-black rounded p-3 my-auto align-items-center', direction: 'vertical', gap: 5 },
                        React.createElement("h1", null, "Welcome"),
                        React.createElement(Image, { width: 70, height: 70, src: customLogo ? customLogo : `${urlBase}/images/twin-logo-white.svg` }),
                        React.createElement(Stack, { gap: 2, className: 'mx-3' },
                            React.createElement(FloatingLabel, { controlId: "floatingInput", label: "Email address" },
                                React.createElement(Form.Control, { value: email, type: "email", placeholder: "name@example.com", className: 'bg-white text-black', onChange: (e) => setEmail(e.target.value), disabled: isError })),
                            React.createElement(FloatingLabel, { controlId: "floatingPassword", label: "Password" },
                                React.createElement(Form.Control, { value: password, type: "password", placeholder: "Password", className: 'bg-white text-black', onChange: (e) => setPassword(e.target.value), disabled: isError }))),
                        React.createElement(Button, { className: 'py-2 px-5 pill-shape', disabled: isError, onClick: login }, "LOGIN"),
                        React.createElement("small", { className: 'clickable', onClick: props.reset }, "Forgot your password?")))),
            providerEls.length == 0 || React.createElement(Row, null,
                React.createElement(Col, { sm: 8, md: 6, lg: 5, xl: 4, xxl: 3, className: 'mx-auto mt-1' },
                    React.createElement(Stack, { className: 'bg-white text-black rounded p-3 my-auto align-items-center', direction: 'vertical', gap: 3 },
                        React.createElement("h4", null, "Login with"),
                        React.createElement(Stack, { gap: 2, className: 'mx-3' }, providerEls)))),
            React.createElement(Row, null,
                React.createElement(Stack, { className: 'align-items-center mt-5' },
                    React.createElement("span", null,
                        React.createElement("small", null, "\u00A9 2023 TwinLogic PTE Ltd. All rights reserved."))))),
        React.createElement(Alert, { show: isError, variant: 'danger', className: 'fixed-top', dismissible: true, onClose: () => { setIsError(false); } },
            React.createElement(Alert.Heading, null, "Oops! Something went wrong"),
            React.createElement("p", null, "Login is unsuccessful. Email address and/or password do not match with our records.")),
        React.createElement(Alert, { show: isTemp, variant: 'info', className: 'fixed-top', dismissible: true, onClose: () => { goTo(nextPage); } },
            React.createElement(Alert.Heading, null, "You are using a Temporary Password"),
            React.createElement("p", null, "Login is successful. However, your temporary password will expire soon. Please change your password in Profile Settings.")));
}
function ShowPage() {
    const [resetPage, setResetPage] = React.useState(false);
    if (resetPage)
        return React.createElement(ResetPage, null);
    else
        return React.createElement(LoginPage, { reset: () => setResetPage(true) });
}
const root = ReactDOM.createRoot(document.getElementById('main'));
root.render(React.createElement(ShowPage, null));
